import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {

  const navigate = useNavigate();

  const handleAboutBtnClick = () => {
    navigate('about');
  };

  return (
    <div className='home-page-container'>
      <header className='main-header'>
        <div className='main-header-content'>
          <h1 className='header-title'>Your categorised guide to unforgettable experiences</h1>
          <h3 className='header-text'>Browse, save and explore today!</h3>
          <div className='spacing'></div>
          <button className='button' onClick={handleAboutBtnClick}>Learn more</button>
        </div>
      </header>
    </div>
    
  )
}

export default Home